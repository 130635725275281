import React, { useState } from "react"
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"
import "../assets/scss/tour-list.css"

import Header from '../components/header'
import Footer from '../components/footer'

import articlesHeader from '../assets/img/articles.webp'

import { all, categories as categoriesDatas } from '../data/articles'

const Stays = ({ location }) => {
  const articlesByPage = 4
  const [activeCategory, setActiveCategory] = useState('Tous les articles')
  const [articlesDatas, setArticlesDatas] = useState(all)
  
  const [page, setPage] = useState(1)
  const totalPage = articlesDatas.length / articlesByPage + 1

  const pagignationPages = [];
  for(let i = 1; i <= totalPage; i++) {
    pagignationPages.push(
      <li key={`pagination-${i}`} class={i === page ? 'current' : ''}>
        <a href="#" onClick={i === page ? () => {} : () => setPage(i)}>{i}</a>
      </li>
    );
  }

  const categories = [
    { name: 'Tous les articles', articles: all },
    ...categoriesDatas
  ]

  return (
    <main>
			<Helmet>
				<meta charSet="utf-8" />
				<title>CoTraveling - Nos articles (Blog)</title>
				<meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
			</Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="page_content blog-page" style={{backgroundImage: `url(${articlesHeader})`}}>
            <div class="wrap">
                <div class="wrap_float">
                  <div class="title">
                    Nos articles
                  </div>
                  <div class="main">
                    <div class="blog-list two-colums">
                      {articlesDatas.length ? articlesDatas.slice((page - 1) * articlesByPage, page * articlesByPage).map(article => <a key={article.name} href={`/article/${article.slug}`} class="blog-item">
                        <div class="_img" style={{backgroundImage: `url(${article.image})`}}>
                          <div class="sq_parent">
                            <div class="sq_wrap">
                              <div class="sq_content"></div>
                            </div>
                          </div>
                        </div>
                        <div class="_date">{article.date} | {article.category}</div>
                        <div class="_title">
                          {article.name}
                        </div>
                        <div class="_text">
                          {article.description}
                        </div>
                      </a>) : ''}
                      </div>
                      <div class="pagination">
                        <ul>
                          <li class="prev"><a href="#" onClick={() => page > 1 && setPage(prevPage => prevPage - 1)}></a></li>
                          {pagignationPages}
                          <li class="next"><a href="#" onClick={() => page < totalPage && setPage(prevPage => prevPage + 1)}></a></li>
                        </ul>
                      </div>
                    </div>
                    <div class="sidebar">
											<div class="categories--list">
                        <div class="block-title">Catégories</div>
                        <ul>
                          {categories.length ? categories.map(category => <li key={category.name} class={category.name === activeCategory ? 'active' : ''}>
                            <a href="#" onClick={activeCategory === category.name ? () => {} : () => {setActiveCategory(category.name);setArticlesDatas(category.articles);}}>
                              <span class="count">{category.articles.length}</span>
                              <span class="_title">{category.name}</span>
                            </a>
                          </li>) : ''}
                        </ul>
											</div>
											{/*<div class="text_widget" style={{backgroundColor: '#222B46'}}>
                        <div class="_title">
                          Text Widget
                        </div>
                        <div class="_text">
                          Nulla vitae elit libero, a pharetra augue. Nulla vitae elit libero, a pharetra augue. Nulla vitae elit libero, a pharetra augue. Donec sed odio dui. Etiam porta sem malesuada.
                        </div>
                      </div>*/}
        						</div>
                </div>
            </div>
        </div>
				<Footer pathname={location.pathname} />        
      </div>
    </main>
  )
}

export default Stays
